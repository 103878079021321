// --- GLOBAL VARIABLES --- //
var $window = $(window)
var $document = $(document)
var $header = $('.header')
var bpSmall = 771
var bpMedium = 992
var bpLarge = 1200
var classIsActive = 'is-active'
var classIsDisabled = 'is-disabled'
var classIsLast = 'is-last'
var classHasError = 'has-error'
var classIsLoading = 'is-loading'
var classIsOpened = 'is-opened'
var classIsSelected = 'is-selected'
var classIsCollapse = 'is-collapse'
var classIsAnimated = 'is-animated'
var $this = null
var $containers = $('html,body')

// --- COMPONENTS, PARTS --- //
$(document).ready(function () {
  // ---  COMPONENTS --- //
  // Modal
  // componentModal()

  // Parallax
  // componentParallax()

  // Textarea Resize
  componentTextareaResize()

  // Navigation
  componentFullscreenNavigation()

  // AOS
  // componentAOS()

  // Google map
  componentGoogleMaps()

  // Validate forms
  componentValidateForms()

  // Side navigation
  componentSideNavigation()

  // Remove
  componentRemove()

  // Scroll to
  partScrollTo()

  partPlaceholder()
})

var resizeTimer

// --- SCROLL EVENT --- //
$(document).scroll(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
    componentSideNavigation()
  })
})

// --- RESIZE EVENT --- //
$(window).resize(function () {
  if (resizeTimer) {
    window.cancelAnimationFrame(resizeTimer)
  }

  resizeTimer = window.requestAnimationFrame(function () {
    partScrollResize()
  })
})

// --- LOAD EVENT --- //
$(window).bind('load', function () {
  // Swiper
  componentSwiper()

  $('.js-hero-headline-group[data-swiper-slide-index="0"]').addClass(
    classIsActive,
  )

  // Page Loader
  $('body').addClass(classIsActive)
})
