//====================================================
//  Function: Modal
//====================================================
function componentModal() {
  // open modal
  $('.js-modal-trigger').on('click', function () {
    var inst = $(this)
    var modalId = inst.data('modal')
    modalOpen(inst, modalId)
  })

  // close modal
  $('.js-close-modal').on('click', function () {
    if ($('.modal-hidden').length) {
      $('.modal-hidden').removeClass('modal-hidden')
    } else {
      $('.js-modal-overlay').removeClass(classIsActive)
    }
    // $('.js-modal.is-active').find('input, textarea').val('')
    // $('.js-modal.is-active')
    //   .find('.js-checkbox-input-hidden')
    //   .prop('checked', false)
    $(this)
      .closest('.js-modal.' + classIsActive + '')
      .removeClass(classIsActive)
    $('body').removeClass('is-modal-open')
    return false
  })

  // close modal keypress key escape
  $(document).keydown(function (event) {
    if (event.keyCode == 27 && $('.js-modal').hasClass(classIsActive)) {
      $('.js-close-modal').trigger('click')
    }
  })

  // close modal on click outside from modal box
  $(document).on('click', function (event) {
    if (
      !$(event.target).hasClass('js-modal-trigger') &&
      !$(event.target).closest('.js-navigation-trigger').length
    ) {
      if (
        !$(event.target).closest('.js-modal-body').length &&
        !$(event.target).closest('.js-navigation').length
      ) {
        $('.js-close-modal').trigger('click')
        $('.js-modal-overlay').removeClass(classIsActive)
        if ($('.js-navigation').hasClass(classIsCollapse)) {
          $('.js-navigation').removeClass(classIsCollapse)
        }
      }
    }
  })
}

function modalOpen(inst, modalId) {
  var element = inst.data('type')
  if ($('.js-modal.is-active').length) {
    $('.js-modal.' + classIsActive + '').addClass('modal-hidden')
  } else {
    $('.js-modal.' + classIsActive + '').removeClass(classIsActive)
    $('.js-modal-overlay').addClass(classIsActive)
  }
  $('.js-modal[data-modal=' + modalId + ']').addClass(classIsActive)
  $('.js-modal.is-active')
    .find('#' + element)
    .attr('checked', true)
  $('.js-modal.is-active').find('.js-hidden-content').removeClass(classIsActive)
  $('.js-modal.is-active')
    .find('.js-hidden-content[data-content="form-order"]')
    .addClass(classIsActive)

  $('body').addClass('is-modal-open')
  return false
}
