/* ==========================================================================
 *  Initialization Google maps
 * ======================================================================= */
function componentGoogleMaps() {
  $('.js-map-block').each(function () {
    var inst = $(this)
    var id = inst.attr('id')
    var langitude = inst.data('langitude')
    var longitude = inst.data('longitude')
    initMapPharmacy(id, langitude, longitude)
  })
}

var mapOptions = {
  zoom: 17,
  zoomControl: true,
  disableDoubleClickZoom: false,
  mapTypeControl: false,
  scaleControl: true,
  scrollwheel: false,
  panControl: true,
  streetViewControl: false,
  draggable: true,
  overviewMapControl: false,
  overviewMapControlOptions: {
    opened: false,
  },
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          weight: '0.5',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text',
      stylers: [
        {
          lightness: '-50',
        },
        {
          saturation: '-50',
        },
      ],
    },
    {
      featureType: 'administrative.neighborhood',
      elementType: 'labels.text',
      stylers: [
        {
          hue: '#009aff',
        },
        {
          saturation: '25',
        },
        {
          lightness: '0',
        },
        {
          visibility: 'simplified',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        {
          saturation: '0',
        },
        {
          lightness: '100',
        },
        {
          gamma: '2.31',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          lightness: '20',
        },
        {
          gamma: '1',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'labels.text.fill',
      stylers: [
        {
          saturation: '-100',
        },
        {
          lightness: '-100',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          lightness: '0',
        },
        {
          saturation: '45',
        },
        {
          gamma: '4.24',
        },
        {
          visibility: 'simplified',
        },
        {
          hue: '#00ff90',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          saturation: '-100',
        },
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          color: '#666666',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry.stroke',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [
        {
          saturation: '-25',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'transit.station.airport',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry.fill',
      stylers: [
        {
          lightness: '50',
        },
        {
          gamma: '.75',
        },
        {
          saturation: '100',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  //styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":-100},{"gamma":0.5}]}],
}

var pharmacyPlaces = [
  {
    id: '1',
    longitude: 48.16206296939801,
    latitude: 17.14501105377811,
  },
  {
    id: '2',
    longitude: 49.21740492531291,
    latitude: 18.74427463934516,
  },
  {
    id: '3',
    longitude: 48.742124182425236,
    latitude: 19.16275500962238,
  },
]

var mapPharmacyElement
var mapPharmacy
var mapPharmacyBounds
var mapPharmacyGmarkers = []
var markerImg
var markerImgActive

function initMapPharmacy(id, langitude, longitude) {
  mapPharmacyElement = document.getElementById(id)
  mapPharmacy = new google.maps.Map(mapPharmacyElement, mapOptions)
  mapPharmacyBounds = new google.maps.LatLngBounds()

  updatePharmacyMap()
}

function markerSet(image, width, height) {
  return new google.maps.MarkerImage(
    image,
    new google.maps.Size(width, height),
    // The origin for my image is 0,0.
    new google.maps.Point(0, 0),
    // The center of the image is 50,50 (my image is a circle with 100,100)
    new google.maps.Point(width / 2, height),
  )
}

function updatePharmacyMap() {
  var objects = []
  var markers = pharmacyPlaces

  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ')
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    if ($(window).width() > bpSmall) {
      markerImg = markerSet('images/map-marker.png', 42, 55)
      markerImgActive = markerSet('images/map-marker-active.png', 42, 55)
    } else {
      markerImg = markerSet('images/map-marker-mobile.png', 23, 30)
      markerImgActive = markerSet('images/map-marker-active-mobile.png', 23, 30)
    }
  } else {
    if ($(window).width() > bpSmall) {
      markerImg = markerSet('images/map-marker.svg', 42, 55)
      markerImgActive = markerSet('images/map-marker-active.svg', 42, 55)
    } else {
      markerImg = markerSet('images/map-marker-mobile.svg', 23, 30)
      markerImgActive = markerSet('images/map-marker-active-mobile.svg', 23, 30)
    }
  }

  for (var i = 0; i < markers.length; ++i) {
    var latLng = new google.maps.LatLng(
      markers[i].longitude,
      markers[i].latitude,
    )

    var marker = new google.maps.Marker({
      position: latLng,
      draggable: false,
      icon: markerImg,
      active: markerImgActive,
      id: markers[i].id,
    })

    google.maps.event.addListener(marker, 'click', function (e) {
      for (let i = 0; i < mapPharmacyGmarkers.length; i++) {
        mapPharmacyGmarkers[i].setIcon(markerImg)
      }
      var tabId = $('.js-tabs-content-map .js-tab-content-item.is-active').data(
        'id',
      )
      if (tabId == this.id) {
        $(
          '.js-tabs-content-map .js-tab-content-item.' + classIsActive + '',
        ).removeClass(classIsActive)
        return false
      }
      if (this.id.length) {
        this.setIcon(this.active)
        $(
          '.js-tabs-content-map .js-tab-content-item.' + classIsActive + '',
        ).removeClass(classIsActive)
        $(
          '.js-tabs-content-map .js-tab-content-item[data-id="' +
            this.id +
            '"]',
        ).addClass(classIsActive)

        if ($(window).width() < bpMedium) {
          $('html, body')
            .stop()
            .animate({
              scrollTop: $('.tabs-content--map').offset().top - 60,
            })
        }
      }
    })

    // google.maps.event.addListener(mapPharmacy, 'click', function () {
    //   for (let i = 0; i < mapPharmacyGmarkers.length; i++) {
    //     mapPharmacyGmarkers[i].setIcon(this.icon)
    //   }
    // })

    mapPharmacyBounds.extend(marker.position)
    marker.setMap(mapPharmacy)
    mapPharmacyGmarkers.push(marker)
  }

  mapPharmacy.fitBounds(mapPharmacyBounds)
}

function setMapOnAll() {
  for (let i = 0; i < mapPharmacymarkers.length; i++) {
    mapPharmacymarkers[i].setMap(null)
  }
  // markerClusterInst.setMap(null);
}

var markerClusterInst

function updatePharmacyMapCluster(type) {
  // Clustering of markers

  var clusterImg = 'images/cluster-marker.png'

  if (properties.length > 1) {
    var clusterStyles = [
      {
        textColor: '#ed1c24',
        url: clusterImg,
        width: 43,
        height: 58,
        anchorIcon: [8000, 8000],
        cssClass: 'cluster-style',
      },
    ]

    var mcOptions = {
      zoom: 13,
      gridSize: 50,
      maxZoom: 15,
      styles: clusterStyles,
    }

    markerClusterInst = new MarkerClusterer(
      mapPharmacy,
      mapPharmacyGmarkers,
      mcOptions,
    )

    ClusterIcon.prototype.onAdd = function () {
      this.div_ = document.createElement('DIV')
      if (this.visible_) {
        var pos = this.getPosFromLatLng_(this.center_)
        this.div_.style.cssText = this.createCss(pos)

        var innerHtml

        if (this.cluster_.markers_.length > 0) {
          innerHtml =
            "<div class='clusterer-text'>" + this.sums_.text + '</div>'
        }

        this.div_.innerHTML = innerHtml
      }

      var panes = this.getPanes()
      panes.overlayMouseTarget.appendChild(this.div_)

      var that = this
      google.maps.event.addDomListener(this.div_, 'click', function () {
        that.triggerClusterClick()
      })
    }

    google.maps.event.addListener(mapPharmacy, 'click', function () {
      for (var i = 0; i < mapPharmacyGmarkers.length; i++) {
        infoWindow.close()
      }
    })
  }

  var listener = google.maps.event.addListener(
    mapPharmacy,
    'idle',
    function () {
      if (properties.count == 1) {
        mapPharmacy.setZoom(15)
      }
      google.maps.event.removeListener(listener)
    },
  )
}
