//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  heroSlider()
  timelineSlider()
}

var heroSliderInst

function heroSlider() {
  if ($('.js-slider-hero').length) {
    var inst = $('.js-slider-hero'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination')

    heroSliderInst = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: false,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
    })

    heroSliderInst.on('slideChange', function () {
      var heroIndex = heroSliderInst.realIndex
      $('.js-hero-headline-group').removeClass(classIsActive)
      $(
        '.js-hero-headline-group[data-swiper-slide-index="' + heroIndex + '"]',
      ).addClass(classIsActive)

      $('.hero-tab .js-hero-tab-content').removeClass(classIsActive)
      $(
        '.hero-tab .js-hero-tab-content[data-id="hero-slide-' +
          heroIndex +
          '"]',
      ).addClass(classIsActive)
    })
  }
}

function timelineSlider() {
  if ($('.js-slider-timeline').length) {
    var inst = $('.js-slider-timeline'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var timelineSliderInst = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: false,
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
      breakpoints: {
        200: {
          freeMode: true,
          slidesPerView: 'auto',
        },
        460: {
          freeMode: true,
          slidesPerView: 'auto',
        },
        771: {
          slidesPerView: 'auto',
          freeMode: true,
        },
        992: {
          slidesPerView: 6,
          freeMode: false,
        },
        1200: {
          slidesPerView: 6,
          freeMode: false,
        },
      },
    })
  }
}
