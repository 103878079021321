//====================================================
//  Function: Parallax
//====================================================
function componentParallax() {
  if ($('.js-parallax').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax')
    new simpleParallax(parallaxImages, {
      delay: 2,
      orientation: 'down',
      scale: 1.4,
    })
  }

  if ($('.js-parallax-down').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax-down')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      orientation: 'down',
      scale: 1.2,
    })
  }

  if ($('.js-parallax-small').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax-small')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      scale: 1.05,
    })
  }

  // if ($('.quote-content-1').length) {
  //   // List items
  //   let portfolioTimeline = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: '.quote-section-1',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'center center', // end after scrolling 500px beyond the start
  //       scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
  //     },
  //   })

  //   var portfolioOptions = {
  //     opacity: '0',
  //     transform: 'translate3d(0, 30px , 0)',
  //   }

  //   // gsap.utils.toArray('.js-accordion-item').forEach((item) => {
  //   //   portfolioTimeline.from(item, portfolioOptions)
  //   // })
  //   portfolioTimeline.from('.quote-content-1', portfolioOptions)
  // }

  // if ($('.quote-content-2').length) {
  //   // List items
  //   let portfolioTimeline = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: '.quote-section-2',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'center center', // end after scrolling 500px beyond the start
  //       scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
  //     },
  //   })

  //   var portfolioOptions = {
  //     opacity: '0',
  //     transform: 'translate3d(0, 30px , 0)',
  //   }

  //   // gsap.utils.toArray('.js-accordion-item').forEach((item) => {
  //   //   portfolioTimeline.from(item, portfolioOptions)
  //   // })
  //   portfolioTimeline.from('.quote-content-2', portfolioOptions)
  // }

  // if ($('.js-price-list').length) {
  //   gsap.from('.js-price-list', {
  //     transform: 'translate3d(0, 120px , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-price',
  //       end: 'center center',
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('.js-fond').length) {
  //   gsap.from('.js-fond', {
  //     transform: 'scale(0.2)',
  //     opacity: 0,
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '.js-section-fond',
  //       end: 'top center',
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('.js-contact').length) {
  //   gsap.from('.js-contact-left', {
  //     transform: 'translate3d(0, 120px , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '.js-contact',
  //       end: 'center center',
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('#section-why-us').length) {
  //   gsap.from('.js-why-us', {
  //     transform: 'translate3d(0, 50px , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-why-us',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'center center', // end after scrolling 500px beyond the start
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('#section-team').length) {
  //   gsap.from('.js-team-left', {
  //     transform: 'translate3d(-10px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-team',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'center center',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.js-team-right', {
  //     transform: 'translate3d(10px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-team',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'center center',
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('#section-campaign').length && $(window).width() > bpSmall) {
  //   gsap.from('.campaign__item-1', {
  //     transform: 'translate3d(-30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-2', {
  //     transform: 'translate3d(30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-3', {
  //     transform: 'translate3d(-30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-4', {
  //     transform: 'translate3d(30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-5', {
  //     transform: 'translate3d(-30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-6', {
  //     transform: 'translate3d(30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-7', {
  //     transform: 'translate3d(-30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-8', {
  //     transform: 'translate3d(30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  //   gsap.from('.campaign__item-9', {
  //     transform: 'translate3d(-30px, 0 , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '#section-campaign',
  //       start: 'top center', // when the top of the trigger hits the top of the viewport
  //       end: 'bottom bottom',
  //       scrub: 2,
  //     },
  //   })
  // }

  // if ($('.js-vision').length) {
  //   gsap.from('.js-vision-left', {
  //     transform: 'translate3d(0, 120px , 0)',
  //     ease: 'none',
  //     scrollTrigger: {
  //       trigger: '.js-vision',
  //       // start: "top bottom", // the default values
  //       end: 'center center',
  //       scrub: 2,
  //     },
  //   })
  // }
}
