//====================================================
//  Function: Remove block
//====================================================
function componentRemove() {
  $(document).on('click', '.js-clear-window', function (e) {
    $(this).closest('.js-tab-content-item').removeClass(classIsActive)
    for (let i = 0; i < mapPharmacyGmarkers.length; i++) {
      mapPharmacyGmarkers[i].setIcon(markerImg)
    }
    e.preventDefault()
  })
}
